import React from "react"
import "./portfolio.css"
import DownloadIcon from "../../assets/portfolio/download-icon.svg"
import { emails } from "../../../site_data/contactdata.json"

const desktopViewContainer = props => {
  const sendFactSheetRequest = name => {
    let mailingList = ""
    emails.forEach(mail => {
      mailingList += mail + ","
    })
    const mailStr = `mailto:${mailingList}?subject=Request for Fact Sheet From C3 Site&body=I have initiated a request for ${name.replace(
      "&",
      "and"
    )} fact sheet.`

    return window.open(mailStr)
  }

  const { heading, image, pricetag } = props
  return (
    <div className="portfolio-desktop-view-div">
      <p className="portfolio-product-heading">{heading}</p>
      <img className="portfolio-product-image" src={image} />
      <p className="portfolio-product-pricetag">{pricetag}</p>
      <div className="download-docs">
        <img src={DownloadIcon} />
        <button
          className="fact-sheet-btn"
          onClick={() => sendFactSheetRequest(heading)}
        >
          Page Fact Sheet
        </button>
      </div>
    </div>
  )
}

export default desktopViewContainer
