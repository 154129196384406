import React from "react"
import Header from "../components/header/Header"
import FooterNew from "../components/footer/FooterNew"
// import QuaterCircle from "../components/portfolio/quaterCircle"
import CannabisImage from "../assets/portfolio/cannabis.png"
import sandpImage from "../assets/portfolio/sandp.svg"
import bitcoinImage from "../assets/portfolio/bitcoin.svg"
import bseImage from "../assets/portfolio/bse.svg"
import Performance from "../components/about/performance/Performance"
import MobileViewPortfolioContainer from "../components/portfolio/mobileViewContainer"
import DesktopViewPortfolioContainer from "../components/portfolio/desktopViewContainer"
import { portfolioData } from "../../site_data/portfoliopage.json"
import { BrowserRouter } from "react-router-dom"
const Portfolio = () => {
  //improve this
  const portfolioCssData = [
    {
      image: CannabisImage,
      color: "#3B467C",
      backgroundColor: "#C9E9F9",
    },
    {
      image: sandpImage,
      color: "white",
      backgroundColor: "#3B467C",
    },
    {
      image: bitcoinImage,
      color: "#3B467C",
      backgroundColor: "#C9E9F9",
    },
    {
      image: bseImage,
      color: "white",
      backgroundColor: "#3B467C",
    },
  ]
  const pfData = portfolioData.map(({ heading, pricetag }, index) => {
    return {
      heading,
      pricetag,
      image: portfolioCssData[index].image,
      color: portfolioCssData[index].color,
      backgroundColor: portfolioCssData[index].backgroundColor,
    }
  })
  return (
    <div className="body-container_1">
      <Header />
      <div className="pie-container">
        <h1>Our Custom Portfolios</h1>
        {/* <div className="pie-container-upper">
          <QuaterCircle
            quadrant="fourth"
            heading="CANNABIS"
            image={CannabisImage}
            pricetag="93.20% IRR 2020"
            factSheetDoc="/cannabis_fact_sheet.pdf"
            brochurePdfDoc="/cannabis_fact_sheet.pdf"
          />
          <QuaterCircle
            quadrant="first"
            heading="S & P 500"
            image={sandpImage}
            pricetag="43% Average ROI"
            factSheetDoc="/sandp_fact_sheet.pdf"
            brochurePdfDoc="/sandp_fact_sheet.pdf"
          />
        </div>
        <div className="pie-container-lower">
          <QuaterCircle
            quadrant="third"
            heading="INDIA, BSE"
            image={bseImage}
            pricetag="63.97% IRR 2020"
            factSheetDoc=""
            brochurePdfDoc=""
          />
          <QuaterCircle
            quadrant="second"
            heading="CRYPTOCURRENCY"
            image={bitcoinImage}
            pricetag="426% YTD ROI (4/23/2021)"
            factSheetDoc="/crypto_fact_sheet.pdf"
            brochurePdfDoc="/crypto_fact_sheet.pdf"
          />
        </div> */}
        <div className="portfolio-container-desktop">
          {pfData.map(val => {
            return <DesktopViewPortfolioContainer key={val.heading} {...val} />
          })}
        </div>
        {pfData.map(val => {
          return <MobileViewPortfolioContainer key={val.heading} {...val} />
        })}
      </div>

      {/* <Performance /> */}

      <FooterNew />
    </div>
  )
}

export default Portfolio
