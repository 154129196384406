import React from "react"
import "./portfolio.css"

import { emails } from "../../../site_data/contactdata.json"

const mobileViewContainers = props => {
  const sendFactSheetRequest = name => {
    let mailingList = ""
    emails.forEach(mail => {
      mailingList += mail + ","
    })
    const mailStr = `mailto:${mailingList}?subject=Request for Fact Sheet From C3 Site&body=I have initiated a request for ${name.replace(
      "&",
      "and"
    )} fact sheet.`

    return window.open(mailStr)
  }
  const { color, backgroundColor, heading, pricetag, image } = props
  return (
    <div
      className="portfolio-mobile-view-div"
      style={{ color: `${color}`, backgroundColor: `${backgroundColor}` }}
    >
      <p className="quater-content-product-heading">{heading}</p>
      <img src={image} alt="ic" />
      <p className="quater-content-product-pricetag">{pricetag}</p>
      <button
        className="fact-sheet-btn"
        onClick={() => sendFactSheetRequest(heading)}
      >
        Page Fact Sheet
      </button>
    </div>
  )
}

export default mobileViewContainers
